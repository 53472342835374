<template>
  <div class="memberAudit">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="select_Info">
        <div>
          状态:&nbsp;
          <a-radio-group v-model="openRadio"  @change="openChange" button-style="solid">
            <a-radio-button value= 'PENDING' >
              待审核
            </a-radio-button>
            <a-radio-button value= 'AGREED' >
              已通过
            </a-radio-button>
            <a-radio-button value= 'REJECT' >
              已驳回
            </a-radio-button>
          </a-radio-group>
        </div>
        <div style="margin-left: 100px">
          姓名:  &nbsp;<a-input v-model="searchName" placeholder="输入姓名搜索" style="width: 120px" size="default" />&nbsp;&nbsp;&nbsp;
          医院:<a-input v-model="searchHospital" placeholder="输入医院搜索" style="width: 120px;margin-left: 15px" size="default" />
          <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
          <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button>
          </div>
      </div>
      <div class="table_Info">
        <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :rowKey="record => record.id"   :pagination="false"
        >
      <span slot="switch" slot-scope="text,item">
        <span style="display: flex;align-items: center"> <div :style="{'background': item.status === 'PENDING' ? '#0066CC' :item.status === 'AGREED' ?'#009933' : item.status === 'REJECT' ?'#CC0000':'','width':'8px','height':'8px','border-radius': '8px'}" > </div>
          &nbsp;{{item.status === 'PENDING' ? '待审批' :item.status === 'AGREED' ?'已通过' : item.status === 'REJECT' ?'已驳回':''}}</span>
      </span>
          <span slot="operate" slot-scope="text,item">
            <a   v-if="item.status === 'AGREED'" @click="check(item)">查看</a>
            <a   v-else @click="check(item)">审核</a>
      </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="paginationChange" />
        </div>
      </div>


      <a-drawer
          :title="state"
          width="600"
          :visible="auditShow"
          :rules="rules"
          @close="auditClose"
      >
        <h3>注册信息</h3>
        <a-form-model
            ref="audit"
            :model="auditForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="身份" prop="type">
            <a-select v-model="auditForm.type"  placeholder="选择身份" @change="typeChange">
              <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.value">
                {{ list.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="auditForm.name" />
          </a-form-model-item>
          <a-form-model-item prop="hospital_name" label="医院" v-if="auditForm.type !== 1006 & auditForm.type !== 1007 & auditForm.type !== 1005">
            <a-input v-model="auditForm.hospital_name" />
          </a-form-model-item>
          <a-form-model-item prop="hospital_offices" label="科室" v-if="auditForm.type !== 1006 & auditForm.type !== 1007 & auditForm.type !== 1005">
            <a-input v-model="auditForm.hospital_offices" />
          </a-form-model-item>
          <a-form-model-item prop="job_title" label="职称" v-if="auditForm.type !== 1006 & auditForm.type !== 1007 & auditForm.type !== 1005">
            <a-input v-model="auditForm.job_title" />
          </a-form-model-item>
          <a-form-model-item prop="school" label="学校" v-if="auditForm.type === 1005">
            <a-input v-model="auditForm.school" />
          </a-form-model-item>
          <a-form-model-item prop="specialty" label="专业" v-if="auditForm.type === 1005">
            <a-input v-model="auditForm.specialty" />
          </a-form-model-item>
          <a-form-model-item prop="education" label="学历" v-if="auditForm.type === 1005">
            <a-select v-model="auditForm.education"  placeholder="选择学历" @change="BAChange">
              <a-select-option v-for="list in BA" :key="list" :value="list">
                {{ list }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="work_unit" label="单位" v-if="auditForm.type === 1006 || auditForm.type === 1007">
            <a-input v-model="auditForm.work_unit" />
          </a-form-model-item>
          <a-form-model-item prop="post" label="职务" v-if="auditForm.type === 1006 || auditForm.type === 1007">
            <a-input v-model="auditForm.post" />
          </a-form-model-item>
        </a-form-model>
        <h3>认证信息</h3>
        <span v-if=" openSearch === 'REJECT'"  style="color: red">驳回原因:{{auditForm.remark}}</span>
        <div style="margin: 20px">
          <img class="auditPic" :src="auditForm.employee_card_url" alt="" v-if="auditForm.employee_card_url">
          <img class="auditPic" :src="auditForm.practice_license_url" alt="" v-if="auditForm.practice_license_url">
          <img class="auditPic" :src="auditForm.nvq_url" alt="" v-if="auditForm.nvq_url">
        </div>
        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 10px" @click="()=>{this.auditShow = false}">
            取消
          </a-button>
          <a-button v-if="stateOpen === 1" style="marginRight:  10px" type="danger"  @click="()=>this.rejectedShow = true">
            驳回
          </a-button>
          <a-button v-if="stateOpen === 1" style="marginRight: 10px" type="primary" @click="passOk">
            通过
          </a-button>
        </div>
      </a-drawer>

      <a-modal v-model="rejectedShow" title="驳回原因" okType="danger" okText="驳回" @ok="rejectedOk">
        <a-textarea v-model="auditForm.remark">

        </a-textarea>

      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {getAuditId, getAuditInfo, putAudit} from "@/service/memberImport_api";

export default {
  name: "memberAudit",
  data(){
    return{
      spinning: false,
      auditShow:false,
      rejectedShow:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      searchName:'',
      searchHospital:'',
      state:'',
      openRadio:'PENDING',
      auditForm:{

      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      typeInfo:[
        {
          value:1001,
          label:'医生(1001)'
        },
        {
          value:1002,
          label:'护士(1002)'
        },
        {
          value:1003,
          label:'医技(1003)'
        },
        {
          value:1004,
          label:'药师(1004)'
        },
        {
          value:1005,
          label:'医学生(1005)'
        },
        {
          value:1006,
          label:'其他医务从业者(1006)'
        },
        {
          value:1007,
          label:'非医务从业者(1007)'
        },
      ],
      BA:[
        '高中/中专','大专','本科','硕士','博士'
      ],
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '医院',
          dataIndex: 'hospital_name',
        },
        {
          title: '科室',
          dataIndex: 'hospital_offices',
        },
        {
          title: '职称',
          dataIndex: 'job_title',
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'switch' },
        },
        {
          title: '提交时间',
          dataIndex: 'created_time',

        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }],
        type: [
          { required: true, message: '请选择身份', trigger: 'change' }],
        post: [
          { required: true, message: '请输入职务', trigger: 'blur' }],
        work_unit: [
          { required: true, message: '请输入单位', trigger: 'blur' }],
        education: [
          { required: true, message: '请选择学历', trigger: 'change' }],
        specialty: [
          { required: true, message: '请输入专业', trigger: 'blur' }],
        school: [
          { required: true, message: '请输入学校', trigger: 'blur' }],
        job_title: [
          { required: true, message: '请输入职称', trigger: 'blur' }],
        hospital_offices: [
          { required: true, message: '请输入科室', trigger: 'blur' }],
        hospital_name: [
          { required: true, message: '请输入医院', trigger: 'blur' }],
      },
      stateOpen:0,
      openSearch:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"认证审核")
  },
  mounted() {
    this.getAuditList()
  },
  methods: {
    async getAuditList(pageNum,status,name,hospital){
      this.spinning = true
      const Info = await getAuditInfo(pageNum,status,name,hospital)
      if (Info.code === 0 ){
        this.InfoTable = Info.data.wyYs
        this.pagination.total = Info.data.count
      }
      this.spinning = false
    },
    //身份选择
    typeChange(e){
      this.auditForm.type = e
    },
    //学历选择
    BAChange(e){
      this.auditForm.education = e
    },
    //分页跳转
    paginationChange(e){
      this.getAuditList(e,this.openSearch,this.searchName,this.searchHospital)
    },
    //搜索重置
    searchReset(){
      this.getAuditList(1,this.openSearch)
      this.openRadio= this.openSearch
      this.openSearch = ''
      this.searchName = ''
      this.searchHospital = ''
      this.pagination.current = 1
    },
    //确认搜索
    searchOk(){
      this.getAuditList(1,this.openSearch,this.searchName,this.searchHospital)
      this.pagination.current = 1
    },
    //状态搜索
    openChange(e){
      switch (e.target.value){
        case 'PENDING':
          this.getAuditList(1,'PENDING')
          this.pagination.current = 1
          this.openSearch = 'PENDING'
          break;
        case 'AGREED' :
          this.getAuditList(1,'AGREED',)
          this.openSearch = 'AGREED'
          this.pagination.current = 1
          break;
        case 'REJECT':
          this.getAuditList(1,'REJECT',)
          this.openSearch = 'REJECT'
          this.pagination.current = 1
          break;
      }
    },
    //审核按钮
    async check(item){
      if (item.status === 'AGREED'){
        this.state = '查看'
        this.stateOpen = 0
      }else {
        this.state = '审核'
        this.stateOpen = 1
      }
      this.audtId = item.id
      const Info = await getAuditId(item.id)
      this.auditForm = Info.data[0]
      this.auditShow = true
    },
    auditClose(){
      this.auditShow = false
      this.auditForm = {}
    },
    //审核通过
   async passOk(){
      this.$refs.audit.validate(async valid =>{
        if (valid){
          const form = this.auditForm
          if (this.auditForm.type !== 1006 & this.auditForm.type !== 1007 && this.auditForm.type !== 1005){
            this.putForm = {
              type: form.type,
              name: form.name,
              hospital_name: form.hospital_name,
              hospital_offices: form.hospital_offices,
              job_title: form.job_title,
              status: "AGREED"
            }
          }

          if (this.auditForm.type === 1005 ){
            this.putForm={
              type: form.type,
              name: form.name,
              school:form.school,
              specialty:form.specialty,
              education:form.education,
              status: "AGREED"
            }
          }
          if (this.auditForm.type === 1006 || this.auditForm.type === 1007 ){
            this.putForm={
              type: form.type,
              name: form.name,
              work_unit:form.work_unit,
              post:form.post,
              status: "AGREED"
            }
          }
          const Info =await putAudit(this.audtId,this.putForm)
          if (Info.code===0){
            this.$message.success('已通过')
            await this.getAuditList(this.pagination.current,this.openSearch)
            this.$refs.audit.resetFields();
            this.auditShow= false;
            this.auditForm = {}
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //审核驳回
    async rejectedOk(){
      this.$refs.audit.validate(async valid =>{
        if (valid){
          const form = this.auditForm
          if (this.auditForm.type !== 1006 & this.auditForm.type !== 1007 && this.auditForm.type !== 1005){
            this.putForm = {
              type: form.type,
              name: form.name,
              hospital_name: form.hospital_name,
              hospital_offices: form.hospital_offices,
              job_title: form.job_title,
              remark : form.remark,
              status: "REJECT"
            }
          }

          if (this.auditForm.type === 1005 ){
            this.putForm={
              type: form.type,
              name: form.name,
              school:form.school,
              specialty:form.specialty,
              education:form.education,
              remark : form.remark,
              status: "REJECT"
            }
          }
          if (this.auditForm.type === 1006 || this.auditForm.type === 1007 ){
            this.putForm={
              type: form.type,
              name: form.name,
              work_unit:form.work_unit,
              post:form.post,
              remark : form.remark,
              status: "REJECT"
            }
          }
          const Info =await putAudit(this.audtId,this.putForm)
          if (Info.code===0){
            this.$message.success('已驳回')
            await this.getAuditList(this.pagination.current,this.openSearch)
            this.$refs.audit.resetFields();
            this.rejectedShow = false;
            this.auditShow= false;
            this.auditForm = {}
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
  }

}
</script>

<style lang="scss" scoped>
.auditPic{
  width: 90%;
  height: auto;
}
.memberAudit{
  margin: -15px -15px ;
  background-color: rgba(240, 242, 245, 1);
  .select_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    min-height: 400px;
    background-color: white;
  }
}
</style>
